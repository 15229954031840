.container-list-clinic {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  background: #e6e6fa;
  position: relative;
}

.container-list-clinic .container-listExams {
  position: relative;
  background: #e6e6fa;
  width: 100vw;
  height: 100vh;
  display: flex;
  height: auto;
}

.container-list-clinic .container-main {
  height: auto;
}

.container-list-clinic .menu {
  background: #fff;
  display: flex;
  align-content: center;
  justify-content: space-around;
  height: 10vh;
}

.container-list-clinic .menu a {
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}

.header-filter {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.form-filter {
  display: flex;
  flex-direction: column;
}

.input-filter {
  width: 500px;
  height: 32px;
  padding: 2px;
  text-align: center;
  border: 2px solid #e6e6fa;
  border-radius: 10px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
}

.button-filter {
  border: 2px solid #677798;
  background: #677798;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  padding: 2px;
}

.button-filter:hover {
  background: #677798dc;
  border: 2px solid #677798dc;
}

.container-list-clinic .container-main {
  max-width: 100%;
  width: 1350px;
  height: auto;
  min-height: 80vh;
  margin: 0 auto;
  padding: 0 40px;
}

.container-list-clinic .content-list {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}

.container-list-clinic .div-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  font-size: 20px;
  height: auto;
  background: #fff;
  margin: 20px;
  text-align: center;
  padding: 5px;
  box-shadow: 5px 18px 14px 2px rgba(43, 43, 43, 0.219);
}

.container-list-clinic .div-content strong {
  font-size: 0.78em;
}

.container-list-clinic .div-content .download-exam {
  color: #fff;
  position: absolute;
  bottom: -0;
  left: 0;
  margin-bottom: -13px;
  width: 100%;
  display: block;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.container-list-clinic .div-content p {
  color: #677798;
  font-size: 0.75em;
  margin: 0 0 12px;
}

.container-list-clinic .div-content a span {
  font-size: 0.9em;
}

.container-list-clinic .div-content a:hover {
  color: rgba(255, 255, 255, 0.863);
}

/* Paginação */

.paginator-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
}

/* Voltar ao topo */

.container-list-clinic .back-to-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  cursor: pointer;
  bottom: 25px;
  right: 25px;
  padding: 10px;
  color: #1caeff;
}

/* Layout responsivo */

@media (max-width: 769px) {
  .container-list-clinic .container {
    max-width: 400px;
    margin: 0;
  }
  .input-filter {
    width: 330px;
  }
  .container-list-clinic .container-listExams {
    flex-direction: column;
  }
  .container-list-clinic {
    height: 100vh;
  }
  .container-list-clinic .menu {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 10vh;
  }
  .container-list-clinic .menu a {
    margin: 0;
  }
  .container-list-clinic .container-main {
    max-width: 100%;
    width: 550px;
    margin: 0 auto;
    height: auto;
  }
  .container-list-clinic .content {
    justify-content: center;
  }
  .container-list-clinic .back-to-top {
    right: 20;
  }

  .menu {
    font-size: 15px;
  }
}
