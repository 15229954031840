.cdi-logo-div {
  width: 300px;
}

.login-input-border-color {
  border-color: #59a18f !important;
}

.login-btn {
  border-color: #59a18f !important;
  color: #59a18f !important;
}

.img-icon-dash {
  border-style: none;
  width: 50px;
  display: flex;
  align-items: center;
}

.status {
  text-align: center;
  font-weight: bold;
  color: white;
}

.status-pending {
  background-color: gray;
  border: 1px solid gray;
  border-radius: 10px;
}

.status-done {
  background-color: green;
  border: 1px solid green;
  border-radius: 10px;
}

.bg-cornflowerblue {
  background-color: cornflowerblue;
  height: 100vh;
}

.bg-lightgray {
  background-color: lightgray;
}

.margin-y-30 {
  margin: 30px 0 !important;
}

.login-button {
  width: 40px;
  height: 24px;
}
.pre-load {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: auto;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cdi-footer {
  background-color: #31896c;
  padding: 10px;
  color: white;
  height: 10vh;
  font-size: 13.5px;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden !important;
}

.vh-90 {
  height: 90vh;
}

.vh-10 {
  height: 10vh;
}

.div-carousel {
  display: flex;
}

.div-img-login {
  display: flex;
}

.cdi-footer a {
  text-decoration: none;
  color: white;
}

.img-login {
  margin: 5px 0;
  width: 200px;
  height: 100px;
}

@media (max-width: 1000px) {
  .div-carousel {
    display: none;
  }

  .cdi-footer {
    font-size: 13.5px;
  }
}

@media (max-width: 920px) {
  .div-img-login {
    display: none;
  }
}
