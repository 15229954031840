.container-list {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  background: #e1f1ff;
  position: relative;
}

.container-listExams {
  background: #e1f1ff;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
}

.menu {
  background: #fff;
  display: flex;
  /* flex-direction: column;
  align-content: center;
  justify-content: space-between;
  height: 90vh; */

  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 10vh;
}

.menu a {
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}

.container-main {
  max-width: 100%;
  width: 1350px;
  height: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

.header {
  max-width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid rgb(103, 119, 152);
  padding: 5px;
  text-align: center;
}

.header ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 5px;
  font-weight: bold;
  color: #677798;
}

.content-list {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}

.div-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  font-size: 20px;
  min-height: 220px;
  max-height: auto;
  background: #fff;
  margin: 20px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 5px 18px 14px 2px rgba(43, 43, 43, 0.219);
}

.div-content strong {
  font-size: 0.78em;
}

.div-content .download-exam {
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: block;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.div-content p {
  color: #677798;
  font-size: 0.75em;
  margin: 0 0 12px;
}

.div-content a span {
  font-size: 0.9em;
}

.div-content a:hover {
  color: rgba(255, 255, 255, 0.863);
}

/* Voltar ao topo */

.back-to-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  cursor: pointer;
  bottom: 25px;
  right: 25px;
  padding: 10px;
  color: #1caeff;
}

/* Layout responsivo */

@media (max-width: 769px) {
  .container {
    max-width: 400px;
    margin: 0;
  }
  .container-listExams {
    flex-direction: column;
  }
  .container-list {
    height: 100vh;
  }
  .menu {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 10vh;
  }
  .menu a {
    margin: 0;
  }
  .container-main {
    max-width: 100%;
    width: 550px;
    margin: 0 auto;
    height: 90vh;
    height: auto;
  }
  .header {
    background: #e1f1ff;
  }
  .content {
    justify-content: center;
  }
  .back-to-top {
    right: 20;
  }

  .menu {
    font-size: 15px;
  }
}
